<template>
    <div class="h-full w-full flex bg-gray-100">
        <div class="w-full bg-gray-800">
            <div class="h-full bg-gray-100 rounded-lg">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    import { Component, Vue } from "vue-property-decorator";

    @Component({ name: "DefaultLayout" })
    export default class DefaultLayout extends Vue {}
</script>

<style scoped>

</style>